import React, { useEffect } from 'react';
import Slider from 'react-slick';

import "./home.scss";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';


function ViewHome(props) {

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        className: "center",
        autoplaySpeed: 2000,

        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "regular",
                    centerMode: false,
                    // dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "regular",
                    centerMode: false,
                    // dots: true,
                }
            }
        ]
    };

    const slickSlider = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "regular",
                    centerMode: false,
                    dots: true
                }
            }
        ]
    };

    // useEffect(() => {
    //     AOS.init({
    //         once: false,
    //     });
    //     AOS.refresh();
    // }, []);



    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div>
                        <video autoPlay muted loop className="home_myVideo">
                            <source src="images/baner.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="home_banner-caption">
                        <h2 data-aos="fade-down">
                            Keeping your Data
                            Private and Secure
                        </h2>
                        <p data-aos="fade-right">
                            Advancing Tailored Data Management Solutions with Enhanced
                            Blockchain Security and Scaled Privacy
                        </p>
                        <div className="home_banner-cta home_floating">
                            <span data-aos="fade-in">
                                <a href="https://miicall.com/Anryton" target='_blank'  className="home_purple">Let's Talk Blockchain</a>
                            </span>
                            <span data-aos="fade-in">
                                <Link to="/whitepaper" className="home_white-outline">
                                    Whitepaper
                                </Link>
                            </span>
                        </div>
                        <video autoPlay muted loop className="home_myVideo home_myVideo_opacity">
                            <source src="images/hero-video.webm" type="video/webm" />
                        </video>
                    </div>
                </div>
            </div>

            <div className="container home_about-text">
                <div className="row d-flex align-items-center justify-content-center">
                    <div className="home_about-caption" data-aos="fade-right">
                        Anryton stands at the forefront of addressing modern data concerns, offering robust solutions powered by Blockchain technology.
                    </div>
                </div>

                <div className="row d-flex align-items-center justify-content-center">
                    <div className="home_about-desc" data-aos="fade-left">
                        We don't just ensure your data's security; we champion its integrity, ensuring that the trust and confidence of both individuals and organizations remain uncompromised. Data is the new gold and we help you in protecting it. We're architecting that future where data doesn't just move - it flows seamlessly and securely.
                    </div>
                </div>
            </div>
            <div className="container-fluid home_img-wrap">
                <div className="row">
                    <div className="col-md-12 home_about-img-pd">
                        <div className="row">
                            <div className="col-md-3 home_about-img-mb">
                                <div className="home_about-img home_img-valign-mt20" data-aos="fade-right">
                                    <img src="images/about-img2.jpg" />
                                </div>
                            </div>
                            <div className="col-md-3 home_about-img-mb">
                                <div className="home_about-img home_img-valign-mt-min20" data-aos="fade-down">
                                    <img src="images/about-img3.jpg" />
                                </div>
                            </div>
                            <div className="col-md-3 home_about-img-mb">
                                <div className="home_about-img home_img-valign-mt50" data-aos="fade-up">
                                    <img src="images/about-img4.jpg" />
                                </div>
                            </div>
                            <div className="col-md-3 home_about-img-mb">
                                <div className="home_about-img brr-0 home_img-valign-mt20" data-aos="fade-left">
                                    <img src="images/about-img6.jpg" className="brr-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home_press-annoucements">
                <h2 data-aos="fade-in">
                    Press Announcements
                </h2>

            </div>
            <div className='home_logo-slider'>
                <Slider {...settings}>
                    <div className='home_slider-logo-img'>
                        <img src="images/press1.png" />
                    </div>
                    <div className='home_slider-logo-img'>
                        <img src="images/press2.png" />
                    </div>
                    <div className='home_slider-logo-img' >
                        <img src="images/press3.png" />
                    </div>
                    <div className='home_slider-logo-img'>
                        <img src="images/press4.png" />
                    </div>
                    <div className='home_slider-logo-img'>
                        <img src="images/press5.png" />
                    </div>
                    <div className='home_slider-logo-img'>
                        <img src="images/press6.png" />
                    </div>
                </Slider>
            </div>

            <div className="container-fluid home_commitment2">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="home_commitment">
                                <h2 data-aos="fade-in">
                                    Our Commitment to<br />
                                    Data Sovereignty and Security
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="col-md-3 text-center home_commit-row" data-aos="fade-right">
                                            <div className="home_commitment-content">
                                                <span>
                                                    <img src="images/private.svg" />
                                                </span>
                                                <h1>Private</h1>
                                                <p>
                                                    Reclaim your digital sovereignty with privacy-first data management
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center home_commit-row" data-aos="fade-down">
                                            <div className="home_commitment-content">
                                                <span>
                                                    <img src="images/personalised.svg" />
                                                </span>
                                                <h1>Personalized</h1>
                                                <p>
                                                    Setup your own node or use a dedicated node to store your data, while maintaining a decentralized encrypted backup
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center home_commit-row" data-aos="fade-up">
                                            <div className="home_commitment-content">
                                                <span>
                                                    <img src="images/secure.svg" />
                                                </span>
                                                <h1>Secure</h1>
                                                <p>
                                                    Proof of Stake consensus with the latest cryptographic standards to relieve you from your cybersecurity concerns
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center home_commit-row" data-aos="fade-left">
                                            <div className="home_commitment-content">
                                                <span>
                                                    <img src="images/decentralized.svg" />
                                                </span>
                                                <h1>Decentralized</h1>
                                                <p>
                                                    Individually owned nodes to maintain the Blockchain distributed ledger, combined with personal storage solution
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home_divider home_divider-mb">
                <img src="images/2px.png" />
            </div>

            <div className="container-fluid home_other-industries">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-between home_industries-wrap">
                                <div className="home_industries-text" data-aos="fade-in">
                                    Other Industries
                                </div>
                                <div className="home_industries-wrap">
                                    <div className="home_industries-row">
                                        <div className="home_industries-data" data-aos="fade-down">
                                            <span>
                                                <img src="images/identity.svg" />
                                            </span>
                                            <span className="home_industry-sect">
                                                <h2>
                                                    Identity
                                                </h2>
                                                <p>Securing Digital Presence with Blockchain.</p>
                                            </span>
                                        </div>
                                        <div className="home_industries-data" data-aos="fade-left">
                                            <span>
                                                <img src="images/governance.svg" />
                                            </span>
                                            <span className="home_industry-sect">
                                                <h2>
                                                    Governance
                                                </h2>
                                                <p>Empowering decentralized decision-making and transparency</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="home_industries-row">
                                        <div className="home_industries-data" data-aos="fade-right">
                                            <span>
                                                <img src="images/insurance.svg" />
                                            </span>
                                            <span className="home_industry-sect">
                                                <h2>
                                                    Insurance
                                                </h2>
                                                <p>Decentralized Insurance Solutions for the Digital Age.</p>
                                            </span>
                                        </div>
                                        <div className="home_industries-data" data-aos="fade-up">
                                            <span>
                                                <img src="images/ecommerce.svg" />
                                            </span>
                                            <span className="home_industry-sect">
                                                <h2>
                                                    E-Commerce
                                                </h2>
                                                <p>Blockchain-Powered for Secure Transactions.</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container home_slick-slider-container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 home_silder-left-content'>
                        <h2>
                            Secure your data with Anryton
                        </h2>
                        <p>
                            Privacy Is Our Paramount Principle
                        </p>
                    </div>
                    <div className='col-lg-8 col-md-12 home_slider-right-cards'>
                        <Slider {...slickSlider}>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/digital-narrative.svg" />
                                </span>
                                <h4>
                                    Own your digital narrative
                                </h4>
                                <p>
                                    Control data access with our blockchain-driven, user-centric privacy model.
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/custom-settings.svg" />
                                </span>
                                <h4>
                                    Custom settings, superior control
                                </h4>
                                <p>
                                    Tailor your data security preferences with flexible, individualized features.
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/personalised.svg" />
                                </span>
                                <h4>
                                    Personalized data storage solutions
                                </h4>
                                <p>
                                    Easily configure a bespoke node with our guided setup process.
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/secure.svg" />
                                </span>
                                <h4>
                                    Advance protection, peace of mind
                                </h4>
                                <p>
                                    Stay ahead with our state-of-the-art cryptographic security measures
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/decentralized.svg" />
                                </span>
                                <h4>
                                    Decentralization for enhanced data integrity.
                                </h4>
                                <p>
                                    Own a part of the blockchain, ensuring data remains incorruptible
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/sustainability.svg" />
                                </span>
                                <h4>
                                    Unmatched support, seamless experience
                                </h4>
                                <p>
                                    Experience personalized guidance every step of your data management journey.
                                </p>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>

            <div className="container-fluid home_community-wrap">
                <div className="row">
                    <div className="container">
                        <div>
                            <div className="d-flex align-items-center justify-content-between home_industries-wrap">
                                <div className="home_community-text" data-aos="fade-in">
                                    Join Anryton's<br /> Exclusive Community
                                </div>
                                <div className="container d-flex home_mb-30 home_community-block-wrapper">
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-right">
                                            <h3>
                                                Network with Privacy-Focused Professionals
                                            </h3>
                                            <p>
                                                Join an elite network committed to upholding data privacy
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img1.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-down">
                                            <h3>
                                                Concierge Service for Your Journey
                                            </h3>
                                            <p>
                                                Industry-leading customer support available 24*7
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img2.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-left">
                                            <h3>
                                                Customization at the Core of Service
                                            </h3>
                                            <p>
                                                Choose how to secure your data with customizable security options
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img3.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container d-flex home_community-block-wrapper">
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-right">
                                            <h3>
                                                Feedback Welcomed, Improvements Deployed
                                            </h3>
                                            <p>
                                                Regular user feedback integration ensures a comfortable platform experience
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img4.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-up">
                                            <h3>
                                                Node Autonomy with Decentralized Backups
                                            </h3>
                                            <p>
                                                Now Maintain the autonomy over the data with our decentralized node structure
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img6.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-left">
                                            <h3>
                                                Empowerment through Technology Education
                                            </h3>
                                            <p>
                                                Access resources to understand blockchain's role in secure data management
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img5.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home_press-annoucements">
                <h2 data-aos="fade-in">
                    Strategic Partners
                </h2>

            </div>
            <div className='home_logo-slider'>
                <Slider {...settings}>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_nea.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_anra.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid' >
                        <img src="images/logo_miicall.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_BParo.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_advanced_cardiology.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_bioemr.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_bioaro.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_cardia.png" />
                    </div>
                </Slider>
            </div>
        </div>

    )
}

export default ViewHome