import React from 'react';
import "./footer.scss"
import { Link } from 'react-router-dom';


function Footer(props) {

    return (


        <div className="container-fluid footer_footer-bg">
            <div>
                <div className="footer_cutout">
                    <img src="images/cutout.jpg" data-aos="fade-in" />
                </div>
                <div className="footer_footer-divider">
                    <img src="images/2px.png" />
                </div>
                <div className="container footer_footer-container">
                    <div className="row g-0 text-white mt-5 p-2">
                        <div className="col-md-6 footer_footer-left-content">
                            <div className="mb-5 footer_footer-logo">
                                <img src="images/logo-anryton.svg" alt="" />
                            </div>
                            <div className="footer_footer-icons-container mb-4">
                                <span className="footer_footer-icons">
                                    <a href="#">
                                        <i className="bi bi-medium"></i>
                                    </a>
                                </span>
                                <span className="footer_footer-icons">
                                    <Link to="https://twitter.com/Anry_ton" target="_blank">
                                        <i className="bi bi-twitter"></i>
                                    </Link>
                                </span>
                                <span className="footer_footer-icons">
                                    <Link to="https://ae.linkedin.com/company/anryton" target="_blank">
                                        <i className="bi bi-linkedin"></i>
                                    </Link>
                                </span>
                            </div>
                            <div className="footer_footer-copyright">
                                <p>
                                    &copy; 2024 Anryton. All rights reserved.
                                </p>
                            </div>
                        </div>
                        <div className="footer_about-container col-md-2">
                            <div className="mb-lg-3 mb-2">
                                <p>
                                    Company
                                </p>
                            </div>
                            <div className="footer_footer-listing">
                                <ul>
                                    <li className="mb-2">
                                        <Link to="/anry-Token">ANRY Token</Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/p2p" >P2P</Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="nft-marketplace">NFT Marketplace</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer_about-container col-md-2">
                            <div className="mb-lg-3 mb-1">
                                <p>
                                    About
                                </p>
                            </div>
                            <div className="footer_footer-listing">
                                <ul>
                                    <li className="mb-2">
                                        <Link to="/services">Our Services</Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/cases">
                                            Use Case
                                        </Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/about">
                                            About Us
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer_about-container col-md-2">
                            <div className="mb-lg-3 mb-1">
                                <p>
                                    Learn
                                </p>
                            </div>
                            <div className="footer_footer-listing">
                                <ul>
                                    <li className="mb-2">
                                        <Link to="/whitepaper">
                                            Whitepaper
                                        </Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/contact">
                                            Contact Us
                                        </Link>
                                    </li>
                                    <li className="mb-2">
                                        <Link to="/blog">
                                            Blog
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default Footer