import './App.css';
import AppRoutes from './router';
import { PrimeReactProvider } from 'primereact/api';
import 'primeicons/primeicons.css';


function App() {
  return (
    <PrimeReactProvider>
      <AppRoutes />
    </PrimeReactProvider>
  );
}

export default App;
