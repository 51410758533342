import React from 'react';
import "./blog.scss"
import { TabView, TabPanel } from 'primereact/tabview';
import { Link } from 'react-router-dom';

function ViewBlog(props) {

    return (
        <main>
            <div className="container-fluid blog_commitment-blog blog_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="blog_commitment">
                                <h2 data-aos="fade-in">
                                    Latest Reads
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="blog_page-banner-blog">
                                            Browse through some of the latest blogs and trending articles.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="blog_divider blog_divider-mb">
                <img src="images/2px.png" />
            </div>
            <div className='container'>
                <div className="card blog_card-dark">
                    <TabView scrollable>
                        <TabPanel header="Technical">
                            <div className="container d-flex mt-5 blog_listing-wrap justify-content-center home_mb-30 home_community-block-wrapper">
                                <div className="col-md-4 home_community-block-mb blog_blog-listing">
                                    <Link to="/blog-details" className="block-link">
                                        <div className="home_community-block" data-aos="fade-right">
                                            <h3>
                                                Network with Privacy-Focused Professionals
                                            </h3>
                                            <p>
                                                Join an elite network committed to upholding data privacy
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img1.jpg" />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4 home_community-block-mb blog_blog-listing">
                                    <Link to="/blog-details" className="block-link">
                                        <div className="home_community-block" data-aos="fade-right">
                                            <h3>
                                                Network with Privacy-Focused Professionals
                                            </h3>
                                            <p>
                                                Join an elite network committed to upholding data privacy
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img1.jpg" />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className="col-md-4 home_community-block-mb blog_blog-listing">
                                    <Link to="/blog-details" className="block-link">
                                        <div className="home_community-block" data-aos="fade-right">
                                            <h3>
                                                Network with Privacy-Focused Professionals
                                            </h3>
                                            <p>
                                                Join an elite network committed to upholding data privacy
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img1.jpg" />
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Institutional">
                            <div className='mt-5'>
                                <div className='blog_hourglass-img'>
                                    <img src="/images/hourglass.svg" alt="" />
                                </div>
                                <div className="home_banner-caption blog_text-height">
                                    <h2 data-aos="fade-down">
                                        Coming Soon
                                    </h2>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Sustainability">
                            <div className='mt-5'>
                                <div className='blog_hourglass-img'>
                                    <img src="/images/hourglass.svg" alt="" />
                                </div>
                                <div className="home_banner-caption blog_text-height">
                                    <h2 data-aos="fade-down">
                                        Coming Soon
                                    </h2>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Community">
                            <div className='mt-5'>
                                <div className='blog_hourglass-img'>
                                    <img src="/images/hourglass.svg" alt="" />
                                </div>
                                <div className="home_banner-caption blog_text-height">
                                    <h2 data-aos="fade-down">
                                        Coming Soon
                                    </h2>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Developers">
                            <div className='mt-5'>
                                <div className='blog_hourglass-img'>
                                    <img src="/images/hourglass.svg" alt="" />
                                </div>
                                <div className="home_banner-caption blog_text-height">
                                    <h2 data-aos="fade-down">
                                        Coming Soon
                                    </h2>
                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Case Studies">
                            <div className='mt-5'>
                                <div className='blog_hourglass-img'>
                                    <img src="/images/hourglass.svg" alt="" />
                                </div>
                                <div className="home_banner-caption blog_text-height">
                                    <h2 data-aos="fade-down">
                                        Coming Soon
                                    </h2>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
            </div>
        </main>
    )
}

export default ViewBlog