import React, { useState, useEffect } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import "./whitepaper.scss";

function ViewWhitepaper(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showSidebar, setShowSidebar] = useState(false); // Initialize to false
    const [showButton, setShowButton] = useState(false); // Initialize to false

    useEffect(() => {
        const handleResize = () => {
            setShowButton(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);

        // Call handleResize at mount to set initial state
        handleResize();

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    return (
        <div className="container-fluid">
            <div>
                <div className="container services_container-mt pt-lg-0 pt-5">
                    {showButton && (
                        <div className='whitepaper_bi-list-icon whitepaper_toggler'>
                            <Button onClick={toggleSidebar}>
                                <span>
                                    <i className="bi bi-list"></i>
                                </span>
                                <span className='ms-3 whitepaper_toggle-text'>
                                    Whitepaper Menu
                                </span>
                            </Button>
                        </div>
                    )}
                    <div className="whitepaper_whitepaper-container">

                        <div className={`whitepaper_tab-headers ${showSidebar ? 'show-sidebar' : ''}`}>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(0)}>Introduction</div>
                            <div className="whitepaper_tab-title">Overview</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(1)}>Our Vision</div>
                            <div className="whitepaper_tab-header-sub" onClick={() => setActiveIndex(2)}>The evolution of data and data needs</div>
                            <div className="whitepaper_tab-header-sub whitepaper_lst" onClick={() => setActiveIndex(3)}>Challenges in the current scenario</div>
                            <div className="whitepaper_tab-title">Unserstanding Anryton</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(4)}>Why Anryton</div>
                            <div className="whitepaper_tab-header-sub" onClick={() => setActiveIndex(5)}>Anryton VS Traditional Systems</div>
                            <div className="whitepaper_tab-header-sub" onClick={() => setActiveIndex(6)}>The S.P.A.R.E Framework</div>
                            <div className="whitepaper_tab-header-sub" onClick={() => setActiveIndex(7)}>Embracing Decentralization with Anryton</div>
                            <div className="whitepaper_tab-header-sub whitepaper_lst" onClick={() => setActiveIndex(8)}>Competitor analysis</div>
                            <div className="whitepaper_tab-title">Anryton in Action</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(9)}>How Anryton Simplifies the Decentralization Process</div>
                            <div className="whitepaper_tab-header-sub" onClick={() => setActiveIndex(10)}>Products Tailored for Tomorrow</div>
                            <div className="whitepaper_tab-header-sub whitepaper_lst" onClick={() => setActiveIndex(11)}>Services that Simplify and Support</div>
                            <div className="whitepaper_tab-title">Use Cases</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(12)}>Aviation industry</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(13)}>Healthcare</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(14)}>Education</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(15)}>Supply chain</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(16)}>Legal industry</div>
                            <div className="whitepaper_tab-title">RytonRoots</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(17)}>Anryton Academy</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(18)}>Valuation</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(19)}>Tokenomics</div>
                            <div className="whitepaper_tab-header-sub" onClick={() => setActiveIndex(20)}>Token details</div>
                            <div className="whitepaper_tab-header-sub" onClick={() => setActiveIndex(21)}>Fundraising Allocation</div>
                            <div className="whitepaper_tab-header-sub" onClick={() => setActiveIndex(22)}>Token Sale Round Details</div>
                            <div className="whitepaper_tab-header" onClick={() => setActiveIndex(23)}>Anryton journey</div>
                        </div>
                        <div>
                            <div className="tab-content">
                                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Introduction</h2>
                                        <p>
                                            At Anryton, we believe in a future where industries can easily transform through the inclusion of robust technological solutions. Our vision is to assist Aviation, Supply chain, Healthcare, education, and other critical sectors to combat modern data challenges such as manipulation, security, ownership, and integrity.
                                        </p>
                                        <p>
                                            Our commitment is to lay the "Secure roots" for the very foundation of our evolving digital age.
                                        </p>
                                        <p>
                                            Harnessing the transformative power of Blockchain technology, we've embarked on a mission to make its benefits not just available, but easily accessible for the whole world. Blockchain, in its purest form, offers a transparent, tamper-proof, and decentralized system – and industries can enjoy such benefits through Anryton.
                                        </p>
                                        <p>
                                            The integration of Blockchain often feels challenging and complex which has been a roadblock for many industries. However, it is our mission at Anryton to simplify the implementation of such advanced solutions.
                                        </p>
                                        <p>
                                            Our products such as developer SDKs and peer-to-peer data storage are designed to seamlessly integrate the strengths of blockchain into the core processes of various industries. By simplifying blockchain adoption, Anryton ensures that industries are not just participants, but pioneers in the fourth industrial revolution.
                                        </p>
                                        <p>
                                            Join us as we delve deeper into how Anryton is paving the way for a secure, reliable, and innovative future.
                                        </p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Our Vision</h2>
                                        <p>
                                            At Anryton, we envision a future where data moves seamlessly and securely across the globe, fueling innovation and empowering enterprises.
                                        </p>
                                        <p>
                                            We're the architects of a secure digital realm where data isn’t just protected; it’s optimized to flow with unmatched efficiency.
                                        </p>
                                        <p>
                                            Join us in carving a future where data is the linchpin of progress, securely nestled in the robust architecture of Anryton's innovation.
                                        </p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>The evolution of data and data needs</h2>
                                        <p>From cave drawings to digital footprints, data creation and consumption have transformed drastically. As soon as the first inscribing was made on the cave walls, it started a never-ending journey to capture, store, and understand information. Every digital interaction in the present day is a testament to the data-driven world.</p>
                                        <p>Through this evolution of data, we have witnessed a transformation in accessibility to information.</p>
                                        <p>Today, in the blink of an eye, we generate more data than entire centuries of human history did. </p>
                                        <p>This data, which is now considered as the new gold, has its own challenges. </p>
                                        <p>Historically, the transition from physical to digital storage marked an era where information could be stored in reduced spaces, accessed swiftly, and transferred effortlessly. But the sheer volume and diversity of data we produced soon demanded innovations beyond what we'd ever imagined.</p>
                                        <p>Magnetic tapes and floppy disks, while groundbreaking at their inception, were soon replaced by Hard Disk Drives (HDDs), which offered more storage space and faster access times. With the Internet, networked storage solutions like Network Attached Storage (NAS) and Storage Area Networks (SAN) replaced traditional storage methods. </p>
                                        <p>With USBs, SD cards, External Hard Disks, and so many more solutions, we witnessed extraordinary growth in data storage.</p>
                                        <p>Followed by Cloud storage which made data storage extremely accessible and flexible. Sitting in a cafe with just an Internet connection, anyone can access their data. </p>
                                        <p>However, there is a crucial question that needs to be addressed. </p>
                                        <p>Before addressing the criticality of where the data is being stored, it is crucial to understand how the data is being managed, owned, secured, and accessed.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Challenges in the current scenario</h2>
                                        <h4>1. Privacy Concerns</h4>
                                        <p>Third-party softwares and service providers get easy access to your data. Unauthorized access is not solved through only advanced encryption or security measures because it is the very nature of data storage that defines access to the data.</p>
                                        <h4>2. Centralization Dilemmas</h4>
                                        <p>The data storage industry is dominated by a few major players. This means that a handful of organizations control more than 90% of the data stored in the world. Resulting in monopoly, this centralization becomes a root cause of censorship concerns, scalability issues, unfair pricing, single points of failure, and misuse of data among many such challenges</p>
                                        <h4>3. Control & Ownership Issues</h4>
                                        <p>Questions such as who truly "owns" are frequently asked in various industries. Imagine a medical research team working on huge datasets provided by a big data storage company. The users to whom that data belongs, might not be aware of this. Most cloud service providers claim no ownership of user data, but their terms of service can be complex, leading users to unintentionally give away rights or control. A user never gets notified how and where their data is being used. </p>
                                        <h4>4. Monetization & Exploitation</h4>
                                        <p>As said before, data is the new gold. While users store their data, some platforms sell this data to third parties. The data is then used to train algorithms, target advertisements, or even for research among several other purposes. The original user of the data never receives direct benefits. </p>
                                        <p>As evident from these challenges, technical advancements alone can not provide the perfect solution. Data storage in the 21st century is about ethical considerations where privacy and ownership should never be a trade-off.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Why Anryton</h2>
                                        <p>With the advent of Blockchain technology, data sits on the brink of revolution. </p>
                                        <p>Laying a strong foundation, Blockchain ensures easy, secure, and direct data exchanges between various entities.</p>
                                        <p>By creating a transparent and distributed ledger of data, Blockchain enforces integrity and ownership, making sure every change is tracked and attributed to a specific entity. With Blockchain, data isn’t stored in one central point, reducing the risk of data tampering and increasing security. </p>
                                        <p>Blockchain doesn’t just promise solutions, it provides a path to redefining the foundation of our digital interactions.</p>
                                        <p>Let us understand why Anryton is crucial for various industries and what are the key factors that makes Anryton the most suitable partner for effective data management.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Anryton VS Traditional Systems</h2>
                                        <p>In this section, users can understand why Anryton is crucial for industries looking to build a strong foundation.</p>
                                        <p>The table below outlines some of the most crucial characteristics that every industryshould consider to ensure a seamless flow of secured data for their operations. </p>

                                        <table className='whitepaper_versus'>
                                            <thead>
                                                <th>
                                                    Characteristics
                                                </th>
                                                <th>
                                                    Traditional system
                                                </th>
                                                <th>
                                                    Anryton
                                                </th>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <b>Architecture</b>
                                                    </td>
                                                    <td>
                                                        Typically centralized, where a single entity or group of entities control, manage, and oversee the data. Examples include centralized databases and cloud storage services.
                                                    </td>
                                                    <td>
                                                        Inherently decentralized. Data is managed collectively by a network of computers (nodes), ensuring that control isn't concentrated in the hands of a single entity.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Data security
                                                    </td>
                                                    <td>
                                                        Vulnerable to single points of failure due to centralized structures. Any unauthorized breach can potentially compromise the entire dataset.
                                                    </td>
                                                    <td>
                                                        Enhanced data integrity with cryptographic hashes. Once data is written to the blockchain, it becomes tamper-proof and immutable, making unauthorized alterations virtually impossible.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Security measures</b>
                                                    </td>
                                                    <td>
                                                        Security is maintained through perimeter defenses such as firewalls, intrusion detection systems, and regular software patches. Vulnerable to insider threats and breaches if the centralized entity is compromised.
                                                    </td>
                                                    <td>
                                                        Security is intrinsic, leveraging cryptographic protocols and decentralized consensus mechanisms. The distributed nature makes it resistant to single-point attacks. To compromise the network, a majority of nodes would need to be taken over, which is highly impractical in large networks.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            Security measures</b>
                                                    </td>
                                                    <td>
                                                        Often operates in silos, making it difficult for external parties to audit or verify data without special access.
                                                    </td>
                                                    <td>
                                                        Promote transparency, as transactions are visible to all participants on the network, and everyone can verify the integrity of transactions based on the cryptographic proofs
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Security measures</b>
                                                    </td>
                                                    <td>
                                                        Typically have faster transaction speeds and can be scaled more easily using conventional methods.
                                                    </td>
                                                    <td>
                                                        Competing with traditional systems through compressional algorithms, providing better performance
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            Trust</b>
                                                    </td>
                                                    <td>
                                                        Trust is established through intermediaries or central authorities who validate and secure the data.
                                                    </td>
                                                    <td>
                                                        Trust is algorithmically established through consensus mechanisms, eliminating the need for intermediaries.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>
                                                            Data redundancy
                                                        </b>
                                                    </td>
                                                    <td>
                                                        Redundancy might need to be manually implemented, which can lead to higher costs and potential synchronization issues.
                                                    </td>
                                                    <td>
                                                        Inherent redundancy as data is stored across multiple nodes in the network, enhancing data availability and resilience against failures.
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <b>
                                                            Data redundancy
                                                        </b>
                                                    </td>
                                                    <td>
                                                        Governance is often in the hands of a centralized entity or a select group. Decisions related to changes, upgrades, or policy implementations are top-down.
                                                        Policies and rules can be altered unilaterally by those in control.
                                                    </td>
                                                    <td>
                                                        Inherent redundancy as data is stored across multiple nodes in the network, enhancing data availability and resilience against failures.
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <b>
                                                            Data Ownership
                                                        </b>
                                                    </td>
                                                    <td>
                                                        Users often hand over control of their data to centralized entities. For example, when using cloud services, the service provider often holds the data. Data ownership rights can be murky, leading to potential misuse or unauthorized sale of user data.
                                                    </td>
                                                    <td>
                                                        Blockchain promotes the concept of self-sovereign identity and data ownership. Users have control over their data through cryptographic keys. Data on the blockchain can be tokenized, giving clear ownership rights that can be transparently transferred or sold.
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        <b>
                                                            Data Ownership
                                                        </b>
                                                    </td>
                                                    <td>
                                                        Access control lists (ACLs) or role-based access controls (RBAC) are common. These centralized mechanisms determine who can access what data. Risks include improper configurations or access creep, where individuals retain access rights longer than necessary.
                                                    </td>
                                                    <td>
                                                        ccess and control are determined by cryptographic keys. Only those with the right private key can access or transact with their data. Smart contracts can further automate and enforce access controls, ensuring that permissions are executed as intended.
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>The S.P.A.R.E Framework</h2>
                                        <p>Anryton is built on 5 principles together encapsulated as the SPARE framework. Each principle aligns with the vision of Anryton to provide a safe and seamless flow of data globally.</p>
                                        <h4>Understanding the SPARE framework</h4>
                                        <p>In the following section, each principle has been explained in three phases, how it is enabled, how it aligns with the vision of Anryton, and what significance it stands to offer. </p>
                                        <div className='row'>
                                            <div className='col whitepaper_blk'>
                                                <h2>S</h2>
                                                <p>Security</p>
                                            </div>
                                            <div className='col whitepaper_blk'>
                                                <h2>P</h2>
                                                <p>Peer to peer</p>
                                            </div>
                                            <div className='col whitepaper_blk'>
                                                <h2>A</h2>
                                                <p>Algorithmic innovation</p>
                                            </div>
                                            <div className='col whitepaper_blk'>
                                                <h2>R</h2>
                                                <p>Reliability</p>
                                            </div>
                                            <div className='col whitepaper_blk'>
                                                <h2>E</h2>
                                                <p>Encryption</p>
                                            </div>
                                        </div>
                                        <h3>S - Security</h3>
                                        <h4>1. How Anryton enables it</h4>
                                        <p>Anryton utilizes blockchain technology and advanced compression techniques, ensuring that all data is stored in a decentralized, tamper-proof manner. This inherently robust structure, coupled with state-of-the-art cybersecurity protocols, provides an impervious shield against breaches and malicious activities.</p>
                                        <h4>2. How it aligns with Anryton's vision</h4>
                                        <p>At the very heart of Anryton's vision is the belief in creating a digital future that's both transformative and secure. By placing security at the forefront, Anryton is not just offering a solution, but a promise of trust and integrity.</p>
                                        <h4>3. What is its significance for industries</h4>
                                        <p>In today's digital age, data breaches can result in significant financial, reputational, and operational setbacks. Prioritizing security ensures that industries can confidently move forward in their digital journey, safeguarding both their assets and stakeholder trust.</p>
                                        <h3>P - Peer-to-Peer Technology</h3>
                                        <h4>1. How Anryton enables it</h4>
                                        <p>Anryton has built a network of interconnected computers called nodes, allowing for direct interactions and transactions between users without the need for intermediaries. This ensures swift, transparent, and cost-effective operations, enabling seamless data flow, globally.</p>
                                        <h4>2. How it aligns with Anryton's vision</h4>
                                        <p>Anryton envisions a world where technological processes are streamlined and accessible. By promoting peer-to-peer interactions, we are democratizing access, fostering community participation, and reducing barriers.</p>
                                        <h4>3. What is its significance for industries</h4>
                                        <p>P2P technology eliminates middlemen, leading to cost savings, faster processes, and increased operational efficiency, allowing industries to offer better services and products to their consumers.</p>
                                        <h3>A - Algorithmic Innovation</h3>
                                        <h4>1. How Anryton enables it</h4>
                                        <p>Anryton continually invests in R&D, crafting unique, state-of-the-art algorithms that optimize the functioning of their blockchain solutions, ensuring enhanced performance, scalability, and adaptability.</p>
                                        <h4>2. How it aligns with Anryton's vision</h4>
                                        <p>Innovation lies at the heart of Anryton's mission. By prioritizing algorithmic advancements, we are staying true to our commitment to offer industries the very best in blockchain technology.</p>
                                        <h4>3. What is its significance for industries</h4>
                                        <p>Customized algorithmic solutions ensure industries get a blockchain system that aligns perfectly with their specific needs and challenges, driving efficiency and growth.</p>
                                        <h3>R - Reliability</h3>
                                        <h4>1. How Anryton enables it</h4>
                                        <p>Built on a foundation of robust architecture and redundancy measures, Anryton ensures that its platform offers consistent performance with minimal downtimes, ensuring industries can rely on it round the clock. </p>
                                        <h4>2. How it aligns with Anryton's vision</h4>
                                        <p>Anryton's vision of laying "Secure roots" for the digital age isn't just about security but also about uninterrupted data flow. We strive to offer a platform that industries can trust, come what may.</p>
                                        <h4>3. What is its significance for industries</h4>
                                        <p>Operational hiccups can cost industries both time and money. A reliable blockchain solution ensures consistent operations, customer trust, and uninterrupted growth.</p>
                                        <h3>E - Encryption</h3>
                                        <h4>1. How Anryton enables it</h4>
                                        <p>Anryton employs advanced encryption techniques, ensuring that data, while accessible, remains confidential and protected from unauthorized access.</p>
                                        <h4>2. How it aligns with Anryton's vision</h4>
                                        <p>An integral part of Anryton's vision to combat modern data challenges is ensuring data privacy. Through top-tier encryption, we safeguard the sanctity of data, upholding the privacy and trust of users.</p>
                                        <h4>3. What is its significance for industries</h4>
                                        <p>In an age where data privacy regulations are stringent, and consumer trust is fragile, robust encryption ensures industries remain compliant and maintain the trust of their stakeholders.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Embracing Decentralization with Anryton</h2>
                                        <p>Decentralization is the true form of democracy through which each and every individual is empowered. In essence, decentralization simply means removing intermediaries from various processes and giving the control of data back to its creator. </p>
                                        <p>For industries, gaining control over their data and having a reliable, secure system can be a game changer. </p>
                                        <p>Anryton is an enabler of decentralization among industries and individuals. We are transforming the very bedrock of our society by bringing a fundamental shift to the way we manage, store, and access data. </p>
                                        <p>With Anryton, industries can rely on their underlying data management system and focus solely on their activities resulting in performance, growth, process optimization, and critical operations.</p>
                                        <p>The Internet came with a vision of connecting the world and enabling an unbiased ecosystem. While one of its goals has undoubtedly been completed, the world has become more centralized than ever. Due to the prominence of a few giant companies and the exponentially growing dependency on digital data, integrity, ownership, and security of data are a major concern. This is what Anryton aims to change through Blockchain and decentralization.</p>
                                        <p>Blockchain is a revolutionary concept that aims to deliver on the promise of the Internet and ensure a globally connected world where data flows seamlessly.</p>
                                        <h3>Benefits of Embracing Decentralization with Anryton</h3>
                                        <h4>1. Consistent data flow</h4>
                                        <p>Decentralized systems, due to their nature, enable a consistent data flow without any downtime. In a sense, by making the ecosystem serverless, nodes are always available to access data.</p>
                                        <h4>2. Enhanced Security</h4>
                                        <p>Decentralized networks distribute data across numerous nodes. This distribution makes them resistant to malicious attacks and fraud, as altering data would require changing it across a majority of nodes— a feat almost impossible to achieve.</p>
                                        <h4>3. Transparency and Trust</h4>
                                        <p>With every transaction or change recorded on a public ledger in blockchain-based decentralized systems, stakeholders can trace and verify actions. This transparency fosters trust among users and participants.</p>
                                        <h4>4. Reduced Costs</h4>
                                        <p> Decentralization can eliminate intermediaries, leading to faster, direct peer-to-peer transactions and interactions. The result is reduced costs and more efficient operations.</p>
                                        <h4>5.Improved Data Integrity</h4>
                                        <p>The immutable nature of blockchain means that once data is recorded, it cannot be altered without consensus. This feature ensures data remains genuine and unaltered.</p>
                                        <h4>6. Empowerment and Ownership</h4>
                                        <p> Decentralized systems return control to individual users, allowing them to have ownership of their data and decisions. This shift democratizes access and authority.</p>
                                        <h4>7. Resilience and Uptime</h4>
                                        <p> With multiple nodes and no single point of failure, decentralized systems offer better resilience against system downtimes, ensuring continuous data flow. </p>
                                        <h4>8. Open and Inclusive</h4>
                                        <p> Decentralized platforms, especially Anryton which is based on open-source blockchain models, encourage community participation and innovation. Anyone can join, contribute, and benefit from the network.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Competitor's Analysis</h2>

                                        <table className='whitepaper_versus'>
                                            <thead>
                                                <th>
                                                    Protocol
                                                </th>
                                                <th>
                                                    Security/Encryption
                                                </th>
                                                <th>
                                                    Sharing
                                                </th>
                                                <th>
                                                    File Redundancy
                                                </th>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <b>Anryton</b>
                                                    </td>
                                                    <td>
                                                        custom encryption
                                                    </td>
                                                    <td>
                                                        off chain
                                                    </td>
                                                    <td>
                                                        1x and sharding both
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>IPFS</b>
                                                    </td>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        Public by default
                                                    </td>
                                                    <td>
                                                        1X
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Storj</b>
                                                    </td>
                                                    <td>
                                                        AES-256
                                                    </td>
                                                    <td>
                                                        Off-chain
                                                    </td>
                                                    <td>
                                                        Sharding
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Filecoin</b>
                                                    </td>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        1X
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Arweave</b>
                                                    </td>
                                                    <td>
                                                        Three Fish
                                                    </td>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        1X
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b>Sia</b>
                                                    </td>
                                                    <td>
                                                        Three Fish
                                                    </td>
                                                    <td>
                                                        N/A
                                                    </td>
                                                    <td>
                                                        1X
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>How Anryton Simplifies the Decentralization Process</h2>
                                        <p>Anryton is more than a blockchain solution provider—it's a partner in progress.</p>
                                        <p>By enabling decentralization through unique products and services, we make sure that industries don't just adopt decentralization but thrive within it, leading the way in the fourth industrial revolution.</p>
                                        <p>Anryton guides industries into a decentralized future where potentials are limitless and challenges minimal.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Products Tailored for Tomorrow</h2>
                                        <p>Data goes through 6 stages for any organization. Anryton has come up with 6 unique offerings to cater to every aspect of data for an organization.</p>
                                        <h4>1. Blockchain</h4>
                                        <p>For data generation, Anryton's Blockchain ensures swift, cost-effective transactions, minimizing latency and establishing a transparent, immutable data foundation.</p>
                                        <p>Anryton understands the need for a suitable platform which can act as a base for industrial transformation. While the need for Blockchain implementation is clear, there needs to be a platform that facilitates that implementation. </p>
                                        <p>The Anryton Blockchain is the platform where applications for the future are being built. With its unique compression technique enabling optimized storage and performance, industries can enjoy uninterrupted data flow for their global operations.</p>
                                        <p>The Anryton Blockchain will have special support for developer SDKs. </p>
                                        <p>Anryton believes in empowering creators. With developer-friendly Software Development Kits (SDKs), integration of blockchain becomes a seamless endeavor, allowing developers to incorporate decentralization into their applications without the daunting complexities often associated with such integrations.</p>
                                        <p>The developer SDK of Anryton will be the key to mass adoption, a challenge that most Blockchain companies are facing.</p>
                                        <h4>2. Compression Technique</h4>
                                        <p>At the Collection stage, our unique compression technique condenses massive data sets, enabling faster insights while preserving data integrity.</p>
                                        <h4>3. Peer to Peer Storage</h4>
                                        <p>For data storage, Anryton's decentralized storage distributes data across nodes, reducing single points of failure, ensuring constant availability, and increasing accessibility.</p>
                                        <p>Anryton's Peer-to-Peer (P2P) storage system revolutionizes the way data is stored and accessed.</p>
                                        <p>Instead of relying on centralized data centers or servers, Anryton's P2P storage distributes and saves data across numerous nodes or participants in the network. Using algorithms and cryptographic measures, data is fragmented into smaller chunks.</p>
                                        <p>These chunks are then distributed and stored across various nodes in the network, ensuring no single point of failure.</p>
                                        <h4>4. Advanced Encryption</h4>
                                        <p>Secure vaults for tomorrow's future.</p>
                                        <p>As data is sent for processing, Anryton's encryption safeguards it against breaches, ensuring secure transmission and access, Keeping sensitive information shielded from prying eyes.</p>
                                        <h4>5. NFT Platform</h4>
                                        <p>During data authentication, the NFT Platform by Anryton guarantees auditability and ownership, assuring genuine and unique data points while protecting creators' rights.</p>
                                        <p>Artists, creators, or any user can mint or create non-fungible tokens through Anryton’s NFT platform with ease. Through this platform, Anryton makes it simple and easy to turn digital creations, whether art, music, videos, or any form of digital asset, into a unique, tradable token.</p>
                                        <p>Using Blockchain technology at the backend, the platform ensures a robust ownership mechanism, allowing creators to reap the benefits of their creation no matter how many times it is bought and sold.</p>
                                        <p>With Anryton’s own custofm Blockchain, the NFT platform becomes more efficient and reliable, making it suitable for industry adoption and enterprise-grade solutions.</p>
                                        <h4>6. ANRY Token</h4>
                                        <p>ANRY token streamlines global transactions within the Anryton ecosystem, offering a seamless, efficient medium for data-related services and incentives.</p>
                                        <p>ANRY token is at the heart of the Anryton ecosystem.</p>
                                        <p>All digital interactions in the Anryton ecosystem are completed through the ANRY token including access to storage, real-time transactions, executing smart contracts, and the reward mechanism for various decentralized operations including mining. It is designed to seamlessly integrate with all Anryton solutions, from peer-to-peer storage to its developer SDKs, and the NFT platform.</p>
                                        <p>As industries begin to embrace the Anryton ecosystem, the token's value proposition becomes clearer. ANRY token provides users with a decentralized, secure, and transparent medium of exchange.</p>
                                        <p>Beyond the Anryton ecosystem, ANRY is set to redefine cross-border payments. Traditional international transactions face numerous challenges in processes, hefty fees, and currency conversion. However,ANRY is a decentralized digital asset that allows for instant, peer-to-peer cross-border transactions. The underlying Blockchain ensures unparalleled security and transparency, enabling both senders and recipients to track transactions in real-time, removing any and all possibility of fraud or manipulation.</p>
                                        <p>In the bigger picture, the ANRY token is poised to bridge economic divides, enabling businesses and individuals globally to transact freely, fairly, and instantly.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Services that Simplify and Support</h2>
                                        <h4>Custom Implementation</h4>
                                        <p>Anryton understands that each industry has unique challenges. Therefore, we offer tailored blockchain solutions, ensuring that the adoption of decentralized systems aligns perfectly with specific industry requirements.</p>
                                        <h4>Continuous Training & Support</h4>
                                        <p>Transitioning to a decentralized model is a journey, not a destination. Anryton's team of experts offers continuous training, ensuring industries can leverage the full potential of their solutions. Additionally, their 24/7 support ensures that queries, challenges, or upgrades are addressed promptly.</p>
                                        <h4>Integration & Migration Assistance</h4>
                                        <p>Migrating from a traditional system to a decentralized one can be overwhelming. Anryton's seasoned professionals aid in this migration, ensuring data integrity and system continuity, and making the transition smooth and hassle-free.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Aviation industry</h2>
                                        <h5>Enhancing Security and Transparency with Anryton</h5>
                                        <p>The aviation industry suffers from a complex interplay of logistics, operations, and real-time data. Reflecting on the challenges and setbacks faced by the industry with the current infrastructure, the need for Blockchain in the Aviation industry is undeniable.</p>
                                        <p> What the industry needs is effective data management across various departments, enforcing responsibility in maintaining utmost data integrity and security.</p>
                                        <p> Anryton's introduction to this sector marks the beginning of a new era of enhanced security and unparalleled transparency.</p>
                                        <p> From ticketing to maintenance records and cargo tracking, every piece of data is now recorded on the Anryton Blockchain, ensuring its immutability and authenticity, and eradicating the possibility of opaque processes, ticketing fraud, or misplaced cargo.</p>
                                        <p> With Anryton, Airlines can now offer customers real-time insights into flight statuses, baggage whereabouts, and much more, all powered by our Blockchain and data storage solution. Moreover, aircraft maintenance becomes more transparent, with each component's history, from manufacture to installation, being traceable on the Anryton Blockchain. This ensures compliance, boosts safety, and reduces the risks associated with counterfeit or faulty parts.</p>
                                        <p>In embracing Anryton's decentralized solutions, the aviation industry is not just enhancing its operations but also rebuilding trust with its customers in an era where data integrity is paramount.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Healthcare</h2>
                                        <h4>Empowering Patients and Ensuring Data Integrity with Anryton</h4>
                                        <p>The healthcare sector has long grappled with the challenges of data security, patient privacy, and interoperability.</p>
                                        <p>Anryton's blockchain-driven solutions have transformed these challenges into opportunities. Patient records, often fragmented across various healthcare providers, can now be consolidated and securely stored on the blockchain.</p>
                                        <p>This decentralized approach ensures that the data is not just secure but also readily available when needed, without the hurdles of traditional data silos. Patients are empowered with ownership of their health data, deciding who can access it, and ensuring both privacy and accessibility.</p>
                                        <p>Additionally, the immutability of blockchain ensures that medical histories cannot be tampered with, fostering trust among patients and healthcare professionals. From research data sharing to verifying the authenticity of medications, Anryton's solutions are revolutionizing healthcare, making it more patient-centric, secure, and efficient.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Education</h2>
                                        <h4>Fostering Trust and Verification with Anryton</h4>
                                        <p>Education industry, characterized by academic records, certifications, and intellectual properties, is ripe for the advantages offered by Anryton's decentralized solutions.</p>
                                        <p>With Blockchain's inherent properties, institutions can issue digital certificates that are easily verifiable and tamper-proof. This eradicates the prominent issue of fake degrees and credentials, ensuring authenticity and fostering trust among employers and institutions.</p>
                                        <p>Furthermore, students and educators can safely store and share their work, research, and innovations on the blockchain, ensuring their intellectual properties are protected against plagiarism or unauthorized use. This fosters an environment of genuine knowledge-sharing.</p>
                                        <p>Additionally, with Anryton's peer-to-peer storage, educational resources can be more easily accessible, fostering collaboration and democratizing access to information. As education continues to evolve in the digital age, Anryton's solutions ensure it remains trustworthy, collaborative, and progressive</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Supply chain</h2>
                                        <h4>Streamlining Operations and Ensuring Authenticity with Anryton</h4>
                                        <p>The oldest and most critical industry, supply chain, has been void of a considerable transformation with the ever-evolving digital landscape. Anryton recognizes the need for transforming global supply chains in an effort to strengthen trade, enforce responsibility, and maintain trust.</p>
                                        <p>From manufacturers to end consumers, the integrity and traceability of products and components are paramount. Anryton's decentralized solutions are paving the way for more transparent and efficient supply chain management.</p>
                                        <p>With each product or component recorded on the Blockchain, stakeholders at every stage can verify the origin, processing, and transit details of the products. This transparency combats counterfeit products, ensuring that what reaches the consumer is genuine and of assured quality.</p>
                                        <p>Additionally, Anryton's solutions reduce the need for intermediaries and paperwork, thereby accelerating processes and cutting operational costs. Real-time tracking, powered by blockchain, provides stakeholders with actionable insights, enabling them to address logistical challenges swiftly.</p>
                                        <p>For consumers, this means a clear understanding of product provenance, fostering trust in brands and their offerings. By incorporating Anryton's decentralized solutions, the supply chain industry is reshaping itself, focusing on authenticity, efficiency, and consumer trust in an interconnected global marketplace. </p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Legal industry</h2>
                                        <h4>Reinventing Trust and Document Integrity with Anryton</h4>
                                        <p>The legal industry, often reliant on vast repositories of documents and contracts, stands to benefit greatly from Anryton's blockchain solutions.</p>
                                        <p> Legal documents, contracts, and evidentiary materials, when stored on the blockchain, are rendered tamper-proof. This ensures that every piece of evidence or contractual agreement remains pristine and unchanged, lending credibility to legal proceedings and business transactions.</p>
                                        <p> Furthermore, smart contracts powered by Anryton can automate and enforce the terms of an agreement, reducing disputes and streamlining legal processes. The inherent transparency of the blockchain also aids in the seamless sharing of legal documents, ensuring all involved parties are on the same page and reducing the potential for misunderstandings. Moreover, for intellectual property rights, blockchain provides an immutable timestamp of creation, acting as undeniable proof in cases of disputes. By integrating Anryton's solutions, the legal industry is poised to foster a renewed sense of trust, efficiency, and undeniable integrity, reshaping the way legal professionals operate and serve their clientele.</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Anryton Academy</h2>
                                        <h4>Introducing RytonRoots, the education arm of Anryton.</h4>
                                        <p>By supporting the cause of education, Anryton Academy reinforces Anryton's commitment to a brighter, more inclusive digital future. In doing so, it underscores the company's belief that true progress is achieved not just by creating revolutionary solutions but by ensuring they are understood, embraced, and employed to their utmost potential by all.</p>
                                        <p> Anryton Academy's mission is to empower individuals, businesses, and communities with the knowledge and skills necessary to harness the full potential of Blockchain and associated technologies. We believe in making complex concepts accessible, enabling everyone, regardless of their technical background, to participate confidently in the new digital age.</p>
                                        <p> By fostering an environment of continuous learning and innovation, we aim to bridge the gap between current skills and future demands, ensuring that no one is left behind.</p>
                                        <p>Anryton Academy was formed on the principle that “Education is adoption.”</p>
                                        <p> We have already partnered with leading universities and academic experts, creating a comprehensive curriculum to enable Blockchain adoption from the grassroots level, i.e. in Universities and colleges.</p>
                                        <p> For Anryton, education isn't just about imparting knowledge; it's about ensuring comprehension</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Valuation</h2>
                                        <h4>Pre-revenue valuation: 100 Million</h4>
                                        <p>In the ever-evolving technological landscape, breakthroughs that redefine industries are rare, but our technology is one of those exceptions. Over the past years, we've not only developed a unique solution but created a foundation for reshaping an entire market segment. </p>
                                        <p>Mentioned below are the factors considered for the valuation of the company.</p>
                                        <h4>Research & Development (R&D) Costs</h4>
                                        <p>Year 1 R&D: $10 million</p>
                                        <p> Year 2 R&D: $12 million</p>
                                        <b>Operational Costs: Salaries, rent, utilities, etc.</b>
                                        <p> Year 1 Ops: $2 million</p>
                                        <p> Year 2 Ops: $2.5 million</p>
                                        <h4>Assets</h4>
                                        <p>Intangible Assets (Technology/IP): We have evaluated the technology on the basis of various factors such as existing solutions, advancement in technology, research backing, need in the market, and future potential. </p>
                                        <p>As an outcome, the valuation is standing at $50 million.</p>
                                        <h4>Liability</h4>
                                        <p>Currently, the company has no liabilities. All dues have been paid in full. </p>
                                        <h4>Other Factors</h4>
                                        <p>Future Earnings Potential - Revenue model including Subscription model</p>
                                        <p>Market Potential of the business</p>
                                        <p>Strategic Value</p>
                                        <p>Scarcity and Uniqueness</p>
                                        <p>Team</p>
                                        <p>Net Assets</p>
                                        <p> $50 million (tech) + (physical assets) + (cash) - $0 million (debt) - $0 (liabilities) = $50 million </p>
                                        <p> Net Value</p>
                                        <p> Net assets + other factors = $100 million</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Tokenomics</h2>
                                        <h4>ANRY Distribution</h4>
                                        <b>Total Token Supply: 400 million tokens</b>
                                        <div>Angel Sale: 3%</div>
                                        <div>Private Sale: 6%</div>
                                        <div>Public Sale: 6%</div>
                                        <div>Team: 10.00% </div>
                                        <div>Reserves: 25.00% </div>
                                        <div>Storage mining allocation: 10.00% </div>
                                        <div>Grants program: 20.00% </div>
                                        <div>Marketing: 10.00% </div>
                                        <div>Advisors: 3.00% </div>
                                        <div>Liquidity/ Exchange listing: 5.00% </div>
                                        <div> Staking: 2.00% </div>
                                        <br></br>
                                        <div>Fundraising will be done as follows </div>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Token details</h2>
                                        <h4>ANRY is the native token of the Anryton ecosystem.</h4>
                                        <p>The Anryton blockchain uses the ANRY token as a reward mechanism for those who contribute storage space. It also serves as the primary medium for transaction fees within the ecosystem. The upcoming sections detail Anry's distribution and utilities.</p>
                                    </TabPanel>
                                    <TabPanel>
                                        <h2>Fundraising Allocation</h2>
                                        <h4>15% of total supply = 75 million tokens</h4><br />
                                        <b>Cliff Period: </b><span>6 months (During this period, no tokens are released)</span><br></br><br />
                                        <b>Linear Vesting Period: </b><span> 18 months (Following the cliff)</span><br /><br />
                                        <b>Calculations:</b><br />
                                        <p>Tokens to be Vested After the Cliff: Since the entire fundraising allocation is subject to vesting, all 75 million tokens will begin linear vesting after the 6-month cliff. </p>
                                        <p> Monthly Token Release (Post-Cliff): = Total tokens to be vested / Vesting period = 75 million tokens / 18 months = 4.16667 million tokens per month Explanation:</p>
                                        <p> When you have a "cliff" in a vesting schedule, it means that the beneficiaries (e.g., investors, employees) must wait for a specified period before any tokens are released to them. In this case, beneficiaries have to wait for 6 months.</p>
                                        <p> Once the cliff period is over, the tokens begin to vest linearly. For this scenario, over the course of the next 18 months, a constant number of tokens will be released every month. </p>
                                        <p> So, for your fundraising allocation:</p>
                                        <p> Beneficiaries will receive no tokens for the first 6 months due to the cliff. Starting from the 7th month, beneficiaries will receive their respective share of 4.16667 million tokens every month, consistently, for the next 18 months until all 75 million tokens are completely vested.</p>
                                        <p> By the end of the entire period (6 months cliff + 18 months vesting), the beneficiaries will have access to the full 15% (75 million tokens) they were allocated. This method ensures a staggered entry of tokens into the market, providing some level of price protection and reducing the chance of a sudden large sell-off.</p>
                                        <b>Note: We will be having strong anti-collusion Measures: We have Introduced clauses in investment agreements that penalize collusion among investors.</b><br /><br />
                                        <p>The smart contract of the token is architected in a way to ensure the following</p>
                                        <p>1. Maintain a strong supply and demand cycle</p>
                                        <p>2. Risk Fairness and Equality </p>
                                        <p>3. Better Public Perception through decentralized approach in decision making</p>
                                        <p>4. Advanced safety measures to protect from Token Dumping</p>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Token Sale Round Details</h2>
                                        <p>Angel Round has been completed, Private sale is ongoing</p>
                                        <h4>Friends and Family round stats</h4><br />
                                        <div>12 Million Tokens or 3% of the total supply</div>
                                        <div>Status: Completed</div>
                                        <div>6 Months cliff, 18 months linear vesting</div>
                                        <div>Token price - $0.0550 (77% discount)</div>
                                        <div>Amount raised - $7 Million</div>
                                        <div>Total Valuation - $22 Million</div>
                                        <br />
                                        <h4>Private sale stats</h4><br />
                                        <div>24 Million tokens or 6% of the total supply</div>
                                        <div>Status: Ongoing</div>
                                        <div>6 months cliff, 18 months linear vesting</div>
                                        <div>Token price - $0.24 </div>
                                        <div>Amount to be raised - $58 million</div>
                                        <div>Total Valuation - $96 Million</div>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Anryton journey</h2><br />
                                        <h4>Phase 1: Ideation & Foundation (OCT 2021)</h4>
                                        <b>Ideation: </b><span>Conceptualization of core business idea and preliminary planning.</span><br></br><br />
                                        <b>Company Registration: </b><span>Officially registered the company with relevant authorities. </span><br></br><br />
                                        <b>Research:</b><span>Initiated market, technology, and competitor research to validate and refine the business idea. </span><br></br><br />
                                        <b>Consensus:</b><span>Aligned all stakeholders, including founding team and early investors/advisors, on the structure of decentralized Governance.</span><br></br><br />
                                        <h4>Phase 2: Initial Development (MARCH 2022)</h4>
                                        <p>Storage Technology on Blockchain: Began development of our core blockchain storage technology.</p>
                                        <p>Ethereum Integration: Successfully connected our platform with the Ethereum network. </p>
                                        <p>Platform Compatibility: Ensured platform functionality across various devices: desktop, mobile (Windows, Mac, Android & iOS).</p>
                                        <h4>Phase 3: Refinement & Research (JULY 2023)</h4>
                                        <b>Completion of Phase 2: </b><span>Concluded initial development and prepared for more advanced features.</span><br></br><br />
                                        <b>API Integration:</b><span>Launched manual API for seamless integration with our proprietary system. </span><br></br><br />
                                        <b>Compression Technology:</b><span>Initiated research into cutting-edge compression technologies.</span><br></br><br />
                                        <b>Database-less Architecture:</b><span>Implemented and transitioned to a more efficient, database-free architecture.</span><br></br><br />
                                        <h4>Phase 4: Advanced Development (Currently Ongoing)</h4>
                                        <b>Token Launch:</b><span> Launch of ERC20 token ANRY in private and public sale.</span><br></br><br />
                                        <b>Private Ethereum Development:</b><span>Focused on customizing Ethereum functionalities tailored to our unique business needs. </span><br></br><br />
                                        <b>Compression Algorithm Development:</b><span>Diving deep into creating a proprietary compression algorithm for enhanced data efficiency.</span><br></br><br />
                                        <h4>Phase 5: Going Forward (Future plans)</h4>
                                        <p>Private Ethereum Launch: Introducing the private Blockchain setup for lower data storage cost and optimized functions </p>
                                        <p>Developer SDK Launch: Launch of development libraries to encourage innovation in the community </p>
                                        <p>Quantum Algorithm Research: Exploring Post-Quantum consensus and other algorithmic approaches for future-proof solution.</p>
                                        <p>Growth and Expansion: Grow team and community through various measures</p>
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewWhitepaper;
