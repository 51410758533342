
import React, { useState } from "react";
import "./contact.scss"
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
    

function ViewContact(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        let valid = true;

        setNameError('');
        setEmailError('');

        if (name.trim() === '') {
            setNameError('Name is required');
            valid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email.trim() === '') {
            setEmailError('Email is required');
            valid = false;
        } else if (!emailRegex.test(email)) {
            setEmailError('Email is not valid');
            valid = false;
        }

        if (valid) {
            console.log('Form submitted successfully');
        }
    };

    return (
        <div className="container-fluid">
            <div className="container-fluid contact_commitment2 contact_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="row contact_contactus-top-content">
                            <div className="container">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="contact_commitment">
                                        <h2 data-aos="fade-in">
                                            Get In Touch
                                        </h2>
                                        <div className="col-md-12">
                                            <div className="row d-flex">
                                                <div className="contact_page-banner">
                                                    Let’s chat. Get in touch with our sales team to learn more about how Anryton can help you store data with better security and privacy at up to 80% less cost
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contact_divider contact_divider-mb">
                <img src="images/2px.png" />
            </div>

            <div className="container-fluid">
                <div>
                    <div className="container">
                        <div className="row contact_form-container">
                            <div className="col-lg-8 col-md-12 contact_form-section">
                                <div className="contact_form-heading">
                                    <h1>Write to us</h1>
                                    <p>
                                        Start a conversation. Learn how Anryton optimizes data storage costs and Explore more on how Anryton ensures data security.
                                    </p>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div>
                                        <div className="contact_form-input mb-3">
                                        <input
                                            name="name"
                                            type="text"
                                            placeholder="Name*"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                       {nameError && <p style={{ color: 'red' }}>{nameError}</p>}
                                        </div>
                                        <div className="contact_form-input mb-3">
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Email*"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                         {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                                        </div>
                                        <div className="contact_form-input">
                                            <textarea name="" id="" cols="30" rows="5" placeholder="Comment"></textarea>
                                        </div>
                                    </div>
                                    <div className="contact_btn-white  mt-5">
                                        <button type="submit">Submit</button>
                                        {/* <Dialog header="Header" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                                            <p className="m-0">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                                                consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                                            </p>
                                        </Dialog> */}
                                    </div>
                                </form>

                            </div>
                            <div className="col-lg-4 col-md-12 contact_bar-code-img contact_contact-misc-content">
                                <div className="contact_bar-code-wrap">
                                    <div>
                                        <img src="images/bar-code.jpeg" alt="" />
                                    </div>
                                    <div className="contact_purple mt-3 d-flex justify-content-center">
                                        <Button>Let’s talk Blockchain</Button>
                                    </div>
                                </div>
                                <div>
                                    <div className="contact_follow-us">
                                        <h2>
                                            Follow Us on Social Media
                                        </h2>
                                    </div>
                                    <div className="contact_contact-social-icon">
                                        <span className="contact_social-icons">
                                            <Link to="https://twitter.com/Anry_ton" target="_blank">
                                                <i className="bi bi-twitter"></i>
                                            </Link>
                                        </span>
                                        <span className="contact_social-icons">
                                            <Link to="https://ae.linkedin.com/company/anryton" target="_blank">
                                                <i className="bi bi-linkedin"></i>
                                            </Link>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ViewContact